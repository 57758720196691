import { createMuiTheme, Theme } from "@material-ui/core"

import PierSansRegular from "./fonts/PierSans-Regular.otf"
import GothamRoundedBook from "./fonts/GothamRounded-Book.ttf"


import { alert } from "@material-ui/lab/MuiAlert"

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiAlert: alert;
  }
}

const pierSansRegular = {
  fontFamily: "PierSansRegular",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    local('PierSansRegular'),
    url(${PierSansRegular}) format('otf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
}

const gothamRounded = {
  fontFamily: "GothamRoundedBook",
  fontStyle: "normal",
  fontWeight: 900,
  src: `
    local('GothamRoundedBook'),
    url(${GothamRoundedBook}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
}

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    sidebarWidth: number;

    sidebarWidthMD3: number;
    sidebarWidthLG3: number;
    sidebarWidthXL3: number;


    sidebarWidthMD5: number;
    sidebarWidthLG5: number;
    sidebarWidthXL5: number;


    sidebarWidthMDMore: number;
    sidebarWidthLGMore: number;
    sidebarWidthXLMore: number;


    sidebarWidthXL: number;

    sidebarMobileHeight: number;

    colorRed: string;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarWidthXL?: number;
    sidebarMobileHeight?: number;

    sidebarWidthMD3?: number;
    sidebarWidthLG3?: number;
    sidebarWidthXL3?: number;


    sidebarWidthMD5?: number;
    sidebarWidthLG5?: number;
    sidebarWidthXL5?: number;


    sidebarWidthMDMore?: number;
    sidebarWidthLGMore?: number;
    sidebarWidthXLMore?: number;

    colorRed: string;
  }
}

const headerLetterSpacing = 1
let defaultTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#20b4e2"
    },
    info: {
      main: "#000000"
    },
    background: {
      default: "#000000"
    },
    text: {
      primary: "#ffffff"
    }
  },

  typography: {
    fontSize: 9,
    fontFamily: "PierSansRegular"

  },

  props: {
    MuiPaper: {
      variant: "outlined"
    }
  },

  sidebarWidth: 220,
  sidebarWidthXL: 440,

  sidebarWidthMD3: 230,
  sidebarWidthLG3: 310,
  sidebarWidthXL3: 420,


  sidebarWidthMD5: 240,
  sidebarWidthLG5: 290,
  sidebarWidthXL5: 400,


  sidebarWidthMDMore: 200,
  sidebarWidthLGMore: 220,
  sidebarWidthXLMore: 300,


  sidebarMobileHeight: 90,

  colorRed: "#e60000"
})

const { breakpoints } = defaultTheme


const theme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      h1: {
        lineHeight: 1.5,
        fontSize: "1.7rem",
        fontFamily: "GothamRoundedBook",
        fontWeight: "normal",
        [breakpoints.up("xl")]: {
          fontSize: "1.8rem"
        }
      },

      h2: {
        fontSize: "1.3rem",
        fontFamily: "GothamRoundedBook",
        fontWeight: "normal",
        [breakpoints.down("sm")]: {
          fontSize: "1rem"
        },
        [breakpoints.up("xl")]: {
          fontSize: "1.5rem"
        }
      },

      h3: {
        fontFamily: "GothamRoundedBook",
        fontSize: "1rem",
        [breakpoints.down("sm")]: {
          fontSize: "0.8rem"
        },
        [breakpoints.up("xl")]: {
          fontSize: "1.4rem"
        }
      },

      h4: {
        fontFamily: "GothamRoundedBook",
        fontSize: "0.75rem",
        [breakpoints.down("sm")]: {
          fontSize: "0.6rem"
        },
        [breakpoints.up("xl")]: {
          fontSize: "1.1rem"
        }
      },
      h5: {
        fontFamily: "GothamRoundedBook",
        fontSize: "0.65rem",
        [breakpoints.down("sm")]: {
          fontSize: "0.55rem"
        },
        [breakpoints.up("xl")]: {
          fontSize: "1rem"
        }
      },
      h6: {
        fontFamily: "GothamRoundedBook",
        letterSpacing: 0.1,
        fontSize: "0.45rem",
        [breakpoints.up("xl")]: {
          letterSpacing: 0.8,
          fontSize: "0.6rem"
        }
      },
      subtitle1: {
        fontFamily: "PierSansRegular",
        // letterSpacing: 1.2,
        fontSize: "0.62rem",
        [breakpoints.down("sm")]: {
          fontSize: "0.55rem"
        },
        [breakpoints.up("xl")]: {
          fontSize: "0.9rem"
        }
      },
      subtitle2: {

        fontFamily: "PierSansRegular",
        // letterSpacing: 1,
        fontSize: "0.5rem",
        [breakpoints.down("sm")]: {
          fontSize: "0.45rem"
        },
        [breakpoints.up("xl")]: {
          fontSize: "0.7rem"
        }
      },
      body1: {
        // letterSpacing: 1.5,
        fontFamily: "PierSansRegular",
        fontSize: "0.8rem",
        [breakpoints.up("xl")]: {
          fontSize: "1.1rem"
        },
        [breakpoints.down("sm")]: {
          fontSize: "0.77rem"
        }

      },
      body2: {
        fontFamily: "PierSansRegular",
        // letterSpacing: 1,
        lineHeight: 1.57,
        fontSize: "0.71rem",
        [breakpoints.down("sm")]: {
          fontSize: "0.68rem"
        },
        [breakpoints.up("xl")]: {
          fontSize: "0.9rem",
          // letterSpacing: 1.5,
          lineHeight: 1.8
        }
      },
      caption: {
        //letterSpacing: 1,
      },
      button: {
        fontFamily: "GothamRoundedBook, Arial",
        fontSize: "0.75rem",
        [breakpoints.up("xl")]: {
          fontSize: "0.9rem"
        }
      },
      overline: {},
      srOnly: {}
    },

    MuiCssBaseline: {
      "@global": {
        "@font-face": [pierSansRegular, gothamRounded]
      }
    },
    MuiOutlinedInput: {
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #266798 inset",
          WebkitTextFillColor: "#fff"
        }
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: "black"
      },
      outlined: {
        border: "1px solid rgba(255, 255, 255, 0.35)"
      }
    },
    MuiAlert: {
      filledInfo: {
        //color: "#20b4e1"
      }
    },
    MuiButton: {
      root: {
        letterSpacing: "0.1rem"
      }
    }
  }
}


export default theme
