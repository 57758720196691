import React, { FunctionComponent } from "react"
import { motion } from "framer-motion"
import { useTheme } from "@material-ui/core"
import { withTheme } from "@material-ui/core/styles"

export interface Props {
}

export const HoverTapEffect: FunctionComponent<Props> = ({ children }) => {
  const theme = useTheme()

  return (<motion.div
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}>
    {children}
  </motion.div>)
}

export const TapEffect: FunctionComponent<Props> = ({ children }) => {
  const theme = useTheme()

  return (<motion.div
    style={{ height: "100%" }}
    whileTap={{ scale: 0.95 }}>
    {children}
  </motion.div>)
}

class LinkHoverTapRaw extends React.Component {
  render() {

    // @ts-ignore
    return (<motion.div
      whileHover={{ color: this.props.theme.palette.primary.main, opacity: 0.7 }}
      whileTap={{ scale: 0.95, color: this.props.theme.palette.primary.main }}>
      {this.props.children}
    </motion.div>)
  }
}

export const LinkHoverTap = withTheme(LinkHoverTapRaw)

