/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import theme from "../theme"
import CssBaseline from "@material-ui/core/CssBaseline"
import { MuiThemeProvider } from "@material-ui/core/styles"

const Layout = ({ children }) => {

  return (
    <MuiThemeProvider theme={theme}>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
      <CssBaseline/>
      <main>{children}</main>
    </MuiThemeProvider>

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
